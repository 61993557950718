<template>
  <v-container>
    <PageTitle text="Specialties"/>
    <PageText :text="p1" />
    <PageQuote :text="q1" person="Thema Bryant Davis" />
    <PageImage path="img/ss-lillypad.jpg" />
    <PageText :text="p2" />
    <PageQuote :text="q2" person="Gabor Mate" />
    <PageImage path="img/contemplative_dock.jpg" />
    <PageText :text="p3" />
    <PageText :text="p4" />
  </v-container>
</template>

<script>
import PageText from '@/components/PageText.vue';
import PageTitle from '@/components/PageTitle.vue';
import PageQuote from '@/components/PageQuote.vue';
import PageImage from '@/components/PageImage.vue';

export default {
  components: {
    PageText, PageTitle, PageQuote, PageImage,
  },
  data: () => ({
    p1: `
      I primarily specialize in the treatment of trauma. “Trauma” is a word used often in the mental
      health field today. People may think of trauma as experiences of disasters or war. While this
      is true, it is far more encompassing. Trauma encapsulates many forms of abuse (physical,
      emotional, sexual), neglect, natural disasters, accidents, injuries, and partner violence. It
      also includes complex forms of trauma such as experiences of bullying, caregiver
      mis-attunement, grief, divorce, miscarriage, infidelity, frequent moves in childhood and more.
    `,
    p2: `
      Traumatized individuals can find themselves responding in the same ways to the same triggers.
      I am confident in my ability to assist my clients in gaining insight around these patterns and
      provide essential interventions that facilitate healing. As a Certified Clinical Trauma
      Professional (CCTP), I have a variety of competencies to better serve clients with trauma. I
      have made a commitment to continue to educate myself on the most up-to-date interventions for
      trauma treatment.
    `,
    p3: `
      I am also qualified to work with individuals with a variety of mental illnesses and symptoms.
      I am very experienced working with depression, anxiety, panic, grief, mood swings, psychosis,
      and attachment issues. I particularly enjoy assisting people adjust to life transitions
      (changes in job, divorce, graduating, moving, etc).
    `,
    p4: `
      While I provide a wide range of services, there are some that I do not provide in my practice.
      I do not offer couples therapy, work with children/provide play therapy, offer faith-based
      counseling, or provide medication management. If you’re questioning whether I can assist in
      your treatment needs, please feel free to contact me for a consultation.
    `,
    q1: `
      “When trauma has shaped you, try not to confuse who you had to become with who you can be.”
    `,
    q2: `
      “The essence of trauma is a disconnect from the self. Therefore the essence of healing is not
      just uncovering one’s past, but reconnecting with oneself in the present.”
    `,
  }),
};
</script>
